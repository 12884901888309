import Vue from "vue";

export const REPAIR_DELAY_JUSTIFICATIONS = [
  {
    code: '0001',
    description: 'Atraso no inicio das obras a pedido do Segurado'
  },
  {
    code: '0002',
    description: 'Atraso na aquisição/entrega do material'
  },
  {
    code: '0003',
    description: 'Atraso por condições climatéricas'
  },
  {
    code: '0004',
    description: 'Indisponibilidade dos intervenientes'
  },
  {
    code: '0005',
    description: 'Demora na secagem dos matérias'
  },
  {
    code: '0006',
    description: 'Outros'
  },
  {
    code: '0007',
    description: 'Não pagamento da franquia'
  }
];


export async function update(lead_number, payload) {
  const response = await Vue.prototype.$http.put(`/leads/${lead_number}/construction_report`, payload);

  return response.data;
}