<template>
  <v-card flat>
    <v-card-title>
      {{ $capitalize($tc('model.construction_report_construction_report_title')) }}
      <v-spacer />
      <ScopeProvider scope="leads.construction_report.edit">
        <v-btn outlined color="primary" :loading="saving" @click="update">
          {{ __('Salvar', 'schedulings') }}
        </v-btn>
      </ScopeProvider>
    </v-card-title>

    <ValidationObserver ref="construction_report_form">
      <v-card-text>
        <Timestamp
          v-model="form.construction_begin_date"
          :date.sync="construction_begin_date"
          :time.sync="construction_begin_time"
          :label="__('Data de início', 'schedulings')"
        >
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="construction_begin_date"
                type="date"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="construction_begin_time"
                type="time"
                dense
                outlined
              ></v-text-field>  
            </v-col>
          </v-row>
        </Timestamp>

        <Timestamp
          v-model="form.construction_estimated_end_date"
          :date.sync="construction_estimated_end_date"
          :time.sync="construction_estimated_end_time"
          :label="__('Previsão de finalização', 'schedulings')"
        >
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="construction_estimated_end_date"
                type="date"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="construction_estimated_end_time"
                type="time"
                dense
                outlined
              ></v-text-field>  
            </v-col>
          </v-row>
        </Timestamp>
        
        <Timestamp
          v-model="form.construction_end_date"
          :date.sync="construction_end_date"
          :time.sync="construction_end_time"
          :label="__('Data de finalização', 'schedulings')"
        >
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="construction_end_date"
                type="date"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="construction_end_time"
                type="time"
                dense
                outlined
              ></v-text-field>  
            </v-col>
          </v-row>
        </Timestamp>

        <v-row>
          <v-col>
            <v-card-title class="px-0 mb-2">
              <v-icon left> mdi-clock-alert-outline </v-icon> {{ $capitalize($tc('model.construction_report_justification_construction_delay_title')) }}
            </v-card-title>
            <ValidationProvider name="construction_report.construction_delay_justification_details" v-slot="{ errors }">
              <v-select
                :items="list_construction_delay_justifications"
                v-model="form.construction_delay_justification_details"
                item-value="code"
                item-text="description"
                :error-messages="errors"
                :label="__('Justificativa', 'schedulings')"
                outlined
                clearable
              />
            </ValidationProvider>
            <ValidationProvider name="construction_report.construction_delay_justification" v-slot="{ errors }">
              <v-textarea
                :label="$capitalize($tc('model.construction_report_reason_description_text'))"
                v-model="form.construction_delay_justification"
                :error-messages="errors"
                outlined
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-card-text>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { REPAIR_DELAY_JUSTIFICATIONS, update } from "@/services/construction_reports";

import ScopeProvider from "@/components/ScopeProvider";
import Timestamp from "@/components/tools/Timestamp";
import i18n from '@/mixins/i18n';

export default {
  components: { ScopeProvider, Timestamp },
  mixins: [i18n],
  data: function() {
    return {
      form: {},

      saving: false,

      construction_begin_date: "",
      construction_begin_time: "",
      construction_estimated_end_date: "",
      construction_estimated_end_time: "",
      construction_end_date: "",
      construction_end_time: "",
    };
  },
  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    list_construction_delay_justifications() {
      return REPAIR_DELAY_JUSTIFICATIONS;
    }
  },
  methods: {
    async update() {
      const validation = await this.$refs.construction_report_form.validate();
      if (!validation) return;

      this.saving = true;

      try {
        const response = await update(this.$route.params["lead_number"], {
          construction_report: this.form
        });
        this.$store.commit('setLead', {
          ...this.lead,
          default_service: {
            ...this.lead.default_service,
            ...response
          }
        });
        this.$store.commit('sendMessage', {
          text: 'Relatório atualizado com sucesso',
          color: 'green'
        });
      } catch(e) {
        if (e?.response?.data?.errors) {
          this.$refs.construction_report_form.setErrors(e?.response?.data.errors);
        }
        if (e?.response?.data?.message) {
          this.$store.commit('sendMessage', {
            text: e?.response?.data?.message,
            color: 'red'
          });
        }
      } finally {
        this.saving = false;      
      }
    },

    setDateAndTime(date, type) {
      if (date) {
        const date_parts = date.split(" ");

        this[type + '_date'] = date_parts[0],
        this[type + '_time'] = date_parts[1];
      }
    },

    loadForm() {
      this.form = {
        construction_begin_date: this.lead.default_service.construction_begin_date,
        construction_estimated_end_date: this.lead.default_service.construction_estimated_end_date,
        construction_end_date: this.lead.default_service.construction_end_date,
        construction_extimated_price: this.lead.default_service.construction_extimated_price,
        construction_delay_justification_details: this.lead.default_service.construction_delay_justification_details,
        construction_delay_justification: this.lead.default_service.construction_delay_justification
      };

      this.setDateAndTime(this.lead.default_service.construction_begin_date, 'construction_begin');
      this.setDateAndTime(this.lead.default_service.construction_estimated_end_date, 'construction_estimated_end');
      this.setDateAndTime(this.lead.default_service.construction_end_date, 'construction_end');
    },
  },
  created() {
    this.loadForm();
  }
}
</script>

<style>

</style>